<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .row.height.d-flex.justify-content-center.align-items-center
      .nav.nav-pills
        template(v-for="tab in tabs")
          button.nav-link.rounded.border.border-default(data-bs-toggle='tab' :data-bs-target=" '#' + tab.id" type='button' role='tab' :aria-controls='tab.id' aria-selected='false') {{tab.title}}

.container.py-5
  .tab-content
    template(v-for="help in ContactList")
      .tab-pane.fade.show.active(role='tabpanel' :id="help.id")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
Footer
</template>

<script>
import Navbar from "../../components/TheNavbar";
import Footer from "../../components/TheFooter";
export default {
  name: "QuickGuide",
  components: { Footer, Navbar },

  data() {
    return {
      tabs: [
        {
          title: "Step 1 Create a Kloser Account",
          id: "tabOne",
        },
        {
          title: "Step 2 Import Contacts from phonebook",
          id: "tabTwo",
        },
        {
          title: "Step 3 Complete your profile details",
          id: "tabThree",
        },
        {
          title: "Step 4 Create your product/service listings",
          id: "tabFour",
        },
        {
          title: "Step 5 Share website to your contacts",
          id: "tabFive",
        },
        {
          title: "Step 6 Subscription to Kloser",
          id: "tabSix",
        },
        {
          title: "Step 7 Start promoting your business",
          id: "tabSeven",
        },
        {
          title: "Step 8 Update lead information/status",
          id: "tabEight",
        },
      ],
      ContactList: [
        {
          id: "tabOne",
          title: "1.1 What is Kloser",
          description: [
            {
              text: "Kloser is a mobile CRM built for sales professionals who run their businesses from their phone. It works with WhatsApp, SMS, email, and other popular chat apps.",
              imgType: false,
            },
          ],
          explanation: [
            {
              expTitle: "Easily manage your leads",
              expDesc:
                "Notes are for you to remember details about the contact, such as their preferences. These are private to you, and never shown to your clients. This field is optional.",
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 1.png",
            },
            {
              expTitle: "The simplest Marketing advertisement tool ",
              expDesc:
                'The status of this lead. A newly added contact will be marked as "New" for 30 days by default. If there are activities between you and the lead, the status will change to "In progress". If the deal has been closed, you can change the status to "Closed"',
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 2.png",
            },
            {
              expTitle: "Build Your Unique Online Presence",
              expDesc:
                "This section displays all custom groups the client is a part of. Tap “Add new group”  to add the client to the group. This section will be empty if you haven’t created any groups yet.",
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 3.png",
            },
          ],
        },
        {
          id: "tabTwo",
          title: "2.1 How to import contact lists?",
          description: [
            {
              text: "On the client details screen, you can access all the information and actions related to a client or lead. From here you can view and edit your follow up date, groups, notes, and interaction timeline, contact the client, and edit or delete the client.",
              imgType: "yes",
              img: "lead details.png",
            },
            {
              text: "You can call, SMS, WhatsApp, email the client using the buttons at the top of the screen. If the relevant information isn't provided, the contact buttons will be greyed out.",
              imgType: "no",
            },
          ],
          explanation: [
            {
              expTitle: "FIRST TIME USER",
              expDesc:
                "You can choose to import from Phonebook , email contact or upload excel sheet for the 1st contact sync to Kloser.",
              imgType: true,
              img: "Kloser-Page-Empty-lead.png",
            },
            {
              expTitle: "EXISTING USER - Option 1",
              expDesc:
                "If you have few contacts to add, you can manually create or import them from the phonebook via the (+) floating icon on the lead page. You should be able to see the option of  a)import from phone book, b)import from email contact, c)Add contact manually after clicking the (+) button.",
              imgType: true,
              img: "option-1.jpg",
            },
            {
              expTitle: "Import contacts from phonebook:",
              expDesc: "1. Click “Allow” for Kloser to access your contacts",
              imgType: true,
              img: "allow-contact-kloser.jpeg",
            },
            {
              expTitle: "",
              expDesc:
                "2. Click “Import” after select contacts or click “Select all”",
              imgType: true,
              img: "75. Import phonebook_select all.png",
            },
            {
              expTitle: "",
              expDesc: "3. It will pop up a successful message and click “Ok”",
              imgType: false,
            },
            {
              expTitle: "Add contact manually:",
              expDesc:
                "1. Enter the information of person (Name, Mobile number, Email, Job Title, Company & Note) and Click “Save”",
              imgType: true,
              img: "add-contact-manually.jpg",
            },
            {
              expTitle: "Option 2 - Excel ( coming soon)",
              expDesc:
                "If you have many contacts to add, you can bulk import them from via excel file into your Kloser account. This option is only available on Kloser desktop website. You can all the contacts and can choose to skip or keep duplicate contacts that already exist in your account.",
              imgType: false,
            },
          ],
        },
        {
          id: "tabThree",
          title: "3.1 How to Complete Your Profile?",
          description: [
            {
              text: "A complete profile will help you gain more trust from your potential customers who visit your website for the first time. There are three sections that need to be complete : profile picture, banner image and profile details.",
              imgType: false,
            },
          ],
          explanation: [
            {
              expTitle: "Add / change the profile picture to you own:",
              expDesc: "1. Click on edit icon of profile picture",
              imgType: true,
              img: "200.Website profile – empty profile – 3.jpg",
            },
            {
              expTitle: "",
              expDesc:
                "2. Select picture and edit the picture if you need to. Click correct mark icon once it complete",
              imgType: true,
              img: "212.Website-edit profile-crop – 1.jpg",
            },
            {
              expTitle: "Add / change the cover image:",
              expDesc: "1. Click on edit icon of cover picture",
              imgType: true,
              img: "200.Website profile – empty profile – 4.jpg",
            },
            {
              expTitle: "",
              expDesc: "2. Select “Add new photo”",
              imgType: true,
              img: "220.Website-edit banner – 2.jpg",
            },
            {
              expTitle: "",
              expDesc:
                "3. Select picture and edit the picture if you need to. Click correct mark icon",
              imgType: true,
              img: "212.Website-edit profile-crop – 2.jpg",
            },
            {
              expTitle: "",
              expDesc: "4. Click “Save” once it complete",
              imgType: true,
              img: "223.Website slideshow-1 – 1.jpg",
            },
            {
              expTitle: "Add / change the profile details:",
              expDesc: "1. Click the “Manage” at the top of right corner",
              imgType: true,
              img: "200.Website profile – empty profile – 5.jpg",
            },
            {
              expTitle: "",
              expDesc: "2. Click “Manage profile”",
              imgType: true,
              img: "350. Manage website – 1.jpg",
            },
            {
              expTitle: "",
              expDesc:
                "3. Enter the information of background and click “Next” <p class='ps-3'>a) Display name <br/>b) Job Title <br/>c) Company <br/>d) Additional Info (Optional) <br/>e) About me (short intro of company/ product/ services)</p>",
              imgType: true,
              img: "manage-profike.jpg",
            },
            {
              expTitle: "",
              expDesc:
                "4. Enter the information of contact and click “Next”  <p class='ps-3'>a) WhatsApp number <br/>b) Email</p>",
              imgType: true,
              img: "242. Manage profile-2.jpg",
            },
            {
              expTitle: "",
              expDesc:
                "5. Enter the Hashtag (click done on Keyboard to capture the word) for your Specialty (optional) and click “Done” once complete",
              imgType: true,
              img: "245. Manage profile-add tag-3.jpg",
            },
          ],
        },
        {
          id: "tabSix",
          title: "4.3  Subscribe to Kloser",
          description: [
            {
              text: "1. To subscribe to Kloser, go to the “Account” at the bottom tab. Tap on the “Subscription”",
              imgType: true,
              img: "400.Account – empty – 1.png",
            },
            {
              text: "2. You can choose either a monthly or yearly billing cycle. You could save more if you opt in for a yearly plan. ",
              imgType: true,
              img: "695.Subscription-empty.png",
            },
            {
              text: "3. Tap on “Subscribe now” button to proceed ",
              imgType: false,
            },
            {
              text: "4. Select any existing payment method or you can add a new credit/debit card to proceed.",
              imgType: true,
              img: "696.Subscription - choose payment.png",
            },
            {
              text: "5. Tap “Next” to confirm the purchase",
              imgType: true,
              img: "697.Subscription - choose payment – 2.png",
            },
            {
              text: "6. You will receive a confirmation email. ",
              imgType: true,
              img: "698. Subscription - status- subscribed.png",
            },
          ],
          explanation: [
            {
              expTitle: "Note: ",
              expDesc:
                "Please keep in mind that our products are renewed automatically every month or every year, depending on the subscription you acquired. If you unsubscribe, you will still have access to your Kloser premium subscription until the end of the paid period.",
              imgType: false,
            },
          ],
        },
        {
          id: "tabEight",
          title: "2.5 How to edit Lead details?",
          explanation: [
            {
              expTitle: "",
              expDesc:
                "1. You can edit their contact details by tapping the “Edit” button on the top right of the screen. \n",
              imgType: true,
              img: "44.Contact info_tab 2.png",
            },
            {
              expTitle: "",
              expDesc:
                "2. You will see the edit contact page with editable fields",
              imgType: true,
              img: "57-1. Create contact [full + delete].png",
            },
            {
              expTitle: "Name:",
              expDesc:
                "Displayed within the Kloser app only for your reference and searching, but never shown to clients. Usually the same as the contact name in your phonebook. This field is required.",
              imgType: false,
            },
            {
              expTitle: "Mobile Number:",
              expDesc:
                "The phone number of your contact, typically used to send content quickly via apps like WhatsApp or SMS. This field is required.",
              imgType: false,
            },
            {
              expTitle: "Notes:",
              expDesc:
                "Notes are for you to remember details about the contact, such as their preferences. These are private to you, and never shown to your clients. This field is optional.",
              imgType: false,
            },
            {
              expTitle: "Lead status:",
              expDesc:
                'The status of this lead. A newly added contact will be marked as "New" for 30 days by default. If there are activities between you and the lead, the status will change to "In progress". If the deal has been closed, you can change the status to "Closed"',
              imgType: false,
            },
            {
              expTitle: "Delete:",
              expDesc:
                "You can always delete the contact at any time by tapping on the red “Delete” button at the bottom. This will permanently remove it from your lead/group along with any associated data. However, this action won't delete the contacts stored in your mobile phonebook.",
              imgType: false,
            },
            {
              expTitle: "",
              expDesc:
                "3. Once you’re done editing, tap the “Save” button on the top right to save the change on the contact. ",
              imgType: false,
            },
          ],
        },
      ],
    };
  },
};
</script>
